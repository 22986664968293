<template>
  <div style="position: relative;">
    <div class="top_btns clearfix">
      <div class="left_box">
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="filterMethod" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period"></qzf-period>
            </el-form-item>
            <el-form-item label="选择类别 ：" :label-width="formLabelWidth">          
              <el-select v-model="listQuery.optionType" placeholder="请选择" size="small" style="width:200px">
                <el-option label="普通科目明细账" value="1">
                </el-option>
                <el-option label="外币科目明细账" value="3">
                </el-option>
                <el-option label="数量金额科目明细账" value="2">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="外币 ：" :label-width="formLabelWidth">
            <el-checkbox-group size="small" v-model="listQuery.wb">
              <el-checkbox-button v-for="item in wbOption" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>
            </el-form-item>
            <!-- <el-form-item label="会计期间：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item> -->
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.jeNot0" label="金额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fseNot0" label="发生额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fse0Ye1" label="无发生额且余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.omitFse0Month" label="省略中间账期无发生额月份"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>

      </div>
      <div  class="right_box">
        <qzf-button jz="false" @click="printAll()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false" @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>
    <el-row>
      <el-col :span="this.leftStatus?0:5">
        <div class="searchGjz" id="treeBox">
          <el-input v-model="filterText" placeholder="请输入关键字" style="width:100%;margin-bottom: 10px;" size="small"></el-input>
            <el-tree ref="tree" :style="{height:contentStyleObj}" class="elTree" highlight-current :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" node-key="id" style="width:100%;overflow:scroll;box-shadow: rgb(219 211 211 / 58%) 2px 2px 7px 3px;" v-loading="loading">
            </el-tree>
        </div>
      </el-col>
      <el-col :span="this.leftStatus?24:19">
        <el-icon class="leftButtonIcon" @click="stowLeft" v-if="!this.leftStatus"><DArrowLeft /></el-icon>
        <el-icon class="leftButtonIcon" @click="stowLeft" v-else><DArrowRight /></el-icon>
        <div style="margin-bottom:10px;margin-left:25px;position:absolute;top:-2px">
          <span v-if="nowSubjectCode" style="color:var(--themeColor,#17a2b8);font-size: 12px;">{{nowSubjectCode}}
          <el-button size="small" type="primary" @click="handleNodeClick(this.listOne)" icon="Refresh">刷新</el-button>
          </span>
        </div>
        <div v-if="listQuery.optionType == '1'" style="margin-top:10px">
          <el-table stripe :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>
          <el-table-column label="借方" align="center" min-width="150">
            <template #default="scope">
              <span>{{ $comdify(scope.row.inAmount)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
              <template #default="scope">
                <span>{{ $comdify(scope.row.outAmount)}}</span>
              </template>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <template #default="scope">
              <span>{{ $comdify(scope.row.endAmount)}}</span>
            </template>
          </el-table-column>
        </el-table>
        </div>
        <div v-if="listQuery.optionType == '2'" style="margin-top:10px">
          <el-table :height="contentStyleObj" size="small" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
          <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{this.comdify(scope.row.countIn)}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutDan" label="单价" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.priceIn)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutJin" label="金额" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.inAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{this.comdify(scope.row.countOut)}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutDD" label="单价" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.priceOut)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutDJ" label="金额" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.outAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{this.comdify(scope.row.endCount)}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutYD" label="单价" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.endPrice)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutYJ" label="金额" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.endAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        </div>
        <div v-if="listQuery.optionType == '3'" style="margin-top:10px">
          <el-table :height="contentStyleObj" size="small" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
          <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutW" label="外币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.wbInAmount)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutBen" label="本位币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.inAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutDW" label="外币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.wbOutAmount)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutDB" label="本位币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.outAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutYY" label="外币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.wbEndAmount)}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutYB" label="本位币" width="120">
              <template #default="scope">
                <span>{{$comdify(scope.row.endAmount)}}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- <printVouch></printVouch> -->
    <printVouch ref="printVouch" :name="this.name1" :listQuery1="this.listQuery" :DY="this.DY"></printVouch>
    <exportFile ref="exportFile" name="book_detail_account" :listQuery1="this.listQuery"></exportFile>
  </div>
</template>

<script>
import { subjectTreeList } from "@/api/subject.js"
import { bookDetailAccountList } from "@/api/book.js"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
export default {
  name:'detailAccount',
  components: { printVouch,exportFile },
  data() {
    return {
      name1:'',
      DY: 0,
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      filterText: '',
      // optionType: '1',
      listLoading: false,
      loading:false,

      data:[],
      defaultProps: {
        children: 'children',
        label: 'name3',
      },
      list: [],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        subjectId: 0,
        // fzhsId: 0,
        level: 0,
        not0: false,
        jeNot0:true,
        fseNot0:false,
        optionType: '1',
        subjectType:"detailAccount",
        wb:[],
        fse0Ye1:false,
        omitFse0Month:false,
      },
      leftStatus:false,
      nowSubjectCode:'',
      listOne:undefined,
      isShowTooltip:true,
      wbOption:[ 
        {
          value: '美元',
          label: '美元',
        },
        {
          value: '欧元',
          label: '欧元',
        },
        {
          value: '港币',
          label: '港币',
        },
        {
          value: '日元',
          label: '日元',
        },
        {
          value: '英镑',
          label: '英镑',
        },
        {
          value: '韩元',
          label: '韩元',
        },
        {
          value: '卢布',
          label: '卢布',
        },
      ],
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(270)
    this.getList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    async getList(e,period) {
      this.loading = true
      if(e){
        this.listQuery.fseNot0 = false
        this.listQuery.jeNot0 = false
        this.listQuery.not0 = false
        this.listQuery.fse0Ye1 = false
        this.listQuery.omitFse0Month = false
      }
      await subjectTreeList(this.listQuery).then(res=>{
        this.data = res.data.data.list
        this.loading = false
      })
      if(e){
        e.id = e.subjectId
        if(period){
          this.listQuery.period = period
        }
        this.handleNodeClick(e)
      }
      if(this.filterText){
        this.$refs.tree.filter(this.filterText)
      }
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    printAll(e) {
      console.log(e);
      this.DY = e
      this.name1 = 'book_detail_account',
      this.$nextTick(()=>{
      this.$refs.printVouch.init()
      })
      
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name3.indexOf(value) !== -1
    },
    handleNodeClick(item) {
      if(item){
        this.listOne = item
        this.listQuery.subjectId = Number(item.id)
      }
      // this.listQuery.fzhsId = Number(item.fzhsItemId)
      this.listLoading = true
      bookDetailAccountList(this.listQuery).then(res => {
        if(res.data.data.info) {
          this.list = res.data.data.info
          this.listLoading = false
          this.nowSubjectCode = this.list[0].subjectCode
        }else {
          this.list = []
          this.listLoading = false
        }
      })
    },
    async filterMethod(){
      await subjectTreeList(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          this.data = res.data.data.list
        }
      })
      if(this.filterText){
        this.$refs.tree.filter(this.filterText)
      }
      this.listLoading = true
      bookDetailAccountList(this.listQuery).then(res => {
        if(res.data.data.info) {
          this.list = res.data.data.info
          this.listLoading = false
          this.nowSubjectCode = this.list[0].subjectCode
        }else {
          this.list = []
          this.listLoading = false
        }
      })
    },
    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('commons/setParam', {addSubjectId: row.subjectId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
    stowLeft(){
      if(this.leftStatus){
        this.leftStatus = false
        //console.log("xx");
      }else{
        this.leftStatus = true
        //console.log("33");

      }
    },
    // 数量的小数位
    comdify(n) {
      if(!n) return n;
      n = n.toString()
      let str = n.split('.');
      let re = /\d{1,3}(?=(\d{3})+$)/g;
      let n1 = str[0].replace(re, "$&,");
      return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
    },
    //重置
    cancel(){
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        subjectId: 0,
        // fzhsId: 0,
        level: 0,
        not0: false,
        jeNot0:false,
        fseNot0:false,
        optionType: '1',
        subjectType:"detailAccount",
        wb:[],
        fse0Ye1:false,
        omitFse0Month:false,
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchBtn {
  text-align: center;
  button {
    margin-right: 5px;
  }
}
.top_btns {
  margin-bottom: 20px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.elTree {
  width: 100%;
  height: 500px;
  overflow: scroll;
  // box-shadow: rgb(219 211 211 / 58%) 5px 8px 8px 3px;
  border: 1px solid #ede5e5;
}
:deep(.el-tree-node){
  min-width: 100%;
  display:table;
}
::-webkit-scrollbar {
    width: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
}
.leftButtonIcon{
  position: relative;
  top: 255px;
  font-size:20px;
  color:var(--themeColor,#17a2b8);
  cursor: pointer;
}
</style>