<template>
  <div style="position: relative;">
  <!-- top -->
    <div class="top_btns clearfix">
      <div class="left_box">
        <span>账期：</span>
        <qzf-period v-model:period="listQuery.period" v-model:initPeriod="listQuery.initPeriod" @success="getList" :lastStartPeriod="lastStartPeriod" style="width: 120px;" v-model:existOld="existOld"></qzf-period>
        <el-input placeholder="请输入科目名称" v-model="listQuery.subjectName" style="width: 180px;" @keyup.enter="getList" size="small" clearable/>
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;" ></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;" ></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <!-- 筛选 -->
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:21px 20px 0px 6px;width:500px" class="styleForm">
            <el-form-item label="选择类别 ：" :label-width="formLabelWidth">          
              <el-select v-model="wbstatus" placeholder="请选择类别" size="small" style="width:200px">
                <el-option label="普通科目余额表" value="">
                </el-option>
                <el-option label="外币科目余额表" value="wb">
                </el-option>
                <el-option label="数量金额科目余额表" value="sl">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科目编码 ：" :label-width="formLabelWidth">
              <subject-list-all style="width: 136px;" v-model:code="listQuery.subjectCodeStart" v-model:subjectId="listQuery.subjectIdStart"></subject-list-all>
              <span>-</span>
              <subject-list-all style="width: 136px;" v-model:code="listQuery.subjectCodeEnd" v-model:subjectId="listQuery.subjectIdEnd"></subject-list-all>
            </el-form-item>
            <el-form-item label="选择级别 ：" :label-width="formLabelWidth">
              <el-input placeholder="1" style="width: 66px;" class="filter-item" size="small" :disabled="true" />
              <span>-</span>
              <el-input placeholder="请输入级别" v-model.number="listQuery.level" style="width: 66px;margin-right:10px" class="filter-item" size="small" />
            </el-form-item>
            <el-form-item label="外币 ：" :label-width="formLabelWidth">
              <el-checkbox-group size="small" v-model="listQuery.wb">
                <el-checkbox-button v-for="item in wbOption" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <!-- <el-form-item label="会计期间 ：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item> -->
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.jeNot0" label="金额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fseNot0" label="发生额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px">
              <el-checkbox v-model="listQuery.yearAmount" label="隐藏本年累计发生额"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px">
              <el-checkbox v-model="listQuery.fzhsStatus" label="是否显示辅助核算"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <qzf-button type="text" @click="sumsAlert" button_code="zb_kmye_ck">点击查看统计</qzf-button>
        <qzf-button jz="false" @click="printingA5()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false"  @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
        <el-tooltip
          class="item"
          effect="dark"
          content="同步本年数据 "
          placement="top"
          v-if="!this.tongbu"
        >
          <qzf-button jz="false" button_code="zb_kmye_tbkm" @click="tongbuNormal()" type="primary" size="small" icon="Connection">同步</qzf-button>
        </el-tooltip>
        <qzf-button jz="false" button_code="zb_kmye_tbkm" @click="tongbu()" type="primary" size="small" v-if="this.tongbu">同步科目</qzf-button>
        <qzf-button jz="false" button_code="zb_kmye_tbye" @click="tongbu2()" type="primary" size="small" v-if="this.tongbu">同步余额</qzf-button>  
      </div>
    </div>
    <!-- top end -->

    <el-table show-summary :summary-method="getSummaries" stripe :height="contentStyleObj" :data="list" border  v-loading="loading" ref="tableDate" id="tableLazyLoad">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" min-width="90" align="left" fixed show-overflow-tooltip>
        <template #default="scope">
          <span :class="['bianma', scope.row.parentId == 0 ? 'bianma-p' : 'bianma-c']" @click="tiaozhuan(scope.row)">{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>

      <el-table-column label="科目名称" min-width="120" align="left" fixed show-overflow-tooltip>
        <template #default="scope">
          <span :class="['subject-name', getClass(scope.row, 'subjectName')]" @click="tiaozhuan(scope.row)">{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="期初余额" align="center">
        <el-table-column  align="right" prop="periodBeginIn" label="借方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{ $comdify(scope.row.periodBeginIn)}}</span>
          </template>
        </el-table-column>
        <el-table-column  align="right" prop="periodBeginOut" label="贷方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{$comdify(scope.row.periodBeginOut)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodBeginInWb" label="外币借方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.periodBeginInWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodBeginOutWb" label="外币贷方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.periodBeginOutWb)}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="beginCount" label="数量" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{this.comdify(scope.row.beginCount)}}</span>
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="本期发生额" align="center">
        <el-table-column align="right" prop="periodInt" label="借方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{$comdify(scope.row.periodInt)}}</span>
          </template>
        </el-table-column>  
        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="inCount" label="数量借" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{this.comdify(scope.row.inCount)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="periodOut" label="贷方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{$comdify(scope.row.periodOut)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="outCount" label="数量贷" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{this.comdify(scope.row.outCount)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodIntWb" label="外币借方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.periodIntWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodOutWb" label="外币贷方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.periodOutWb)}}</span>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="本年累计发生额" align="center" v-if="!listQuery.yearAmount">
        <el-table-column align="right" prop="yearInt" label="借方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{$comdify(scope.row.yearInt)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="yearOut" label="贷方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn')]">{{$comdify(scope.row.yearOut)}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="yearIntWb" label="外币借方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.yearIntWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="yearOutWb" label="外币贷方" min-width="90">
          <template #default="scope">
            <span :class="getperiodBeginInWbClass(scope.row)">{{$comdify(scope.row.yearOutWb)}}</span>
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="期末余额" align="center">
        <el-table-column align="right" prop="periodEndIn" label="借方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn'), 'pad-r-12']">{{$comdify(scope.row.periodEndIn)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="periodEndOut" label="贷方" min-width="90">
          <template #default="scope">
            <span :class="['period-begin-in', getClass(scope.row, 'periodBeginIn'), 'pad-r-12']">{{$comdify(scope.row.periodEndOut)}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodEndInWb" label="外币借方" min-width="90">
          <template #default="scope">
            <span :class="[getperiodBeginInWbClass(scope.row), 'pad-r-12']">{{$comdify(scope.row.periodEndInWb)}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="right" prop="periodEndOutWb" label="外币贷方" min-width="90">
          <template #default="scope">
            <span :class="[getperiodBeginInWbClass(scope.row), 'pad-r-12']">{{$comdify(scope.row.periodEndOutWb)}}</span>
          </template>
        </el-table-column>


        <el-table-column v-if="wbstatus == 'sl'" align="right" prop="endCount" label="数量" min-width="90">
          <template #default="scope">
            <span :class="[getperiodBeginInWbClass(scope.row), scope.row.subjectCode && 'pad-r-12']">{{this.comdify(scope.row.endCount)}}</span>
          </template>
        </el-table-column>

      </el-table-column>
    </el-table>
    <printVouch ref="printVouch" name="book_subject_balance" :listQuery1="this.listQuery"></printVouch>
    <exportFile ref="exportFile" name="book_subject_balance" :listQuery1="this.listQuery"></exportFile>

    <el-dialog
      title="查看统计"
      v-model="dialogVisible"
      width="60%"
      :before-close="handleClose">
      <div v-html="this.str"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false" size="small">
            关闭
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>


import { oldTongbuSubject,oldNewSubjectSync } from "@/api/old"
import { linkAgeAll } from '@/api/carryover'
import { subjectBalanceList,subjectBalanceSave,wbList } from "@/api/subject"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
import { accountBook } from "@/api/printSet"
import SubjectListAll from '../../../components/subject/subjectListAll.vue'
export default {
  name: 'subjectBalance',
  components: {
    printVouch,
    exportFile,
    SubjectListAll
  },
  props:{
    tongbu:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      name1:'',
      showList:[],
      formLabelWidth: '120px',
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        initPeriod: "",
        jeNot0: true,
        fzhsStatus:false,
        name: '',
        level: 5,
        beginTime:'',
        endTime:'',
        yearAmount:false,
        fseNot0:false,
        wb:[]
      },
      contentStyleObj:{},
      wbstatus: '',
      visible:false,
      checkbox:false,
      loading:false,
      lastStartPeriod:this.$store.getters['user/comInfo'].lastStartPeriod,
      str:'',
      dialogVisible:false,
      comKj:this.$store.getters['user/comInfo'].comKj,
      setScrollTop:'',
      existOld:false,
      wbOption:[ 
        {
          value: '美元',
          label: '美元',
        },
        {
          value: '欧元',
          label: '欧元',
        },
        {
          value: '港币',
          label: '港币',
        },
        {
          value: '日元',
          label: '日元',
        },
        {
          value: '英镑',
          label: '英镑',
        },
        {
          value: '韩元',
          label: '韩元',
        },
        {
          value: '卢布',
          label: '卢布',
        },
      ],
      list:[],
      totalPage:0,
      pageSize:50,
      currentPage:1,
    }
  },

  mounted(){
    this.$refs.tableDate.$refs.bodyWrapper.addEventListener('scroll',(res) => {
      this.setScrollTop = res.target.scrollTop
      localStorage.setItem('subjectScrollTop',res.target.scrollTop)
    },true)
  },
  created() {
    this.initBus()
    this.contentStyleObj= this.$getHeight(200)
    this.getList()
  },
  activated(){
    this.setScrollTop = localStorage.getItem('subjectScrollTop') ? localStorage.getItem('subjectScrollTop') : 0
    if(this.showList.length != 0){
      this.$refs.tableDate.setScrollTop(this.setScrollTop * 1)
    }
  },
  computed: {
    equal() {
      return this.listQuery.period != this.$store.getters['user/comInfo'].startPeriod 
    },
    unequal() {
      return this.listQuery.period == this.$store.getters['user/comInfo'].startPeriod && ((this.listQuery.beginTime == '' && this.listQuery.endTime == '')  || (this.listQuery.beginTime == this.$store.getters['user/comInfo'].startPeriod && this.listQuery.endTime == this.$store.getters['user/comInfo'].startPeriod))
    }
  },
  methods:{
    getperiodBeginInWbClass(row) {
      return !row.subjectCode ? 'periodBeginInWb' : ''
    },
    getClass(row, field) {
      if (row.parentId == 0) {
        return `${field}-one`
      }
      if(row.subjectCode){
        return `${field}-two`
      }
      return `${field}-three`
    },
    initBus(){
      this.$bus.off("subjectBalanceUpdate")
      this.$bus.on("subjectBalanceUpdate", (val) => {
        this.getList()
      });
    },
    setScroll(){
      //计算滚动页数
      this.totalPage = this.showList.length/this.pageSize
      if(this.showList.length % this.pageSize == 0){
        this.totalPage = this.showList.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.showList.length/this.pageSize) + 1
      }
      this.list = this.showList.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.showList.slice(
              0,
              that.currentPage * that.pageSize
            );
          }
        }
      });
    },
    getList(){
      this.loading = true
      if(isNaN(this.listQuery.level*1) || !this.listQuery.level){
        this.$qzfMessage('级别格式有误，请重新输入',1)
        this.listQuery.level = 1
        this.loading = false
        return
      }
      subjectBalanceList(this.listQuery).then(res=>{
        this.loading = false
        this.showList = res.data.data.list ? res.data.data.list : []
        this.assets = res.data.data.assest
        this.liabilities = res.data.data.liabilities
        this.interests = res.data.data.interests
        this.cost = res.data.data.cost
        this.profitAndLoss = res.data.data.profitAndLoss
         // 共同类
         if(res.data.data.list){
          this.gtl = res.data.data.gtl
        }
        let zcTotal = {
          subjectName :"资产合计",
          periodBeginIn:this.assets.periodBeginIn,
          periodBeginOut:this.assets.periodBeginOut,
          periodBeginInWb:this.assets.periodBeginInWb,
          periodBeginOutWb:this.assets.periodBeginOutWb,
          beginCount:this.assets.beginCount,
          periodInt:this.assets.periodInt,
          periodOut:this.assets.periodOut,
          periodIntWb:this.assets.periodIntWb,
          periodOutWb:this.assets.periodOutWb,
          inCount:this.assets.inCount,
          outCount:this.assets.outCount,
          yearInt:this.assets.yearInt,
          yearOut:this.assets.yearOut,
          yearIntWb:this.assets.yearIntWb,
          yearOutWb:this.assets.yearOutWb,
          periodEndIn:this.assets.periodEndIn,
          periodEndOut:this.assets.periodEndOut,
          periodEndInWb:this.assets.periodEndInWb,
          periodEndOutWb:this.assets.periodEndOutWb,
          endCount:this.assets.endCount,
        }
        let fzTotal = {
          subjectName :"负债合计",
          periodBeginIn:this.liabilities.periodBeginIn,
          periodBeginOut:this.liabilities.periodBeginOut,
          periodBeginInWb:this.liabilities.periodBeginInWb,
          periodBeginOutWb:this.liabilities.periodBeginOutWb,
          beginCount:this.liabilities.beginCount,
          periodInt:this.liabilities.periodInt,
          periodOut:this.liabilities.periodOut,
          periodIntWb:this.liabilities.periodIntWb,
          periodOutWb:this.liabilities.periodOutWb,
          inCount:this.liabilities.inCount,
          outCount:this.liabilities.outCount,
          yearInt:this.liabilities.yearInt,
          yearOut:this.liabilities.yearOut,
          yearIntWb:this.liabilities.yearIntWb,
          yearOutWb:this.liabilities.yearOutWb,
          periodEndIn:this.liabilities.periodEndIn,
          periodEndOut:this.liabilities.periodEndOut,
          periodEndInWb:this.liabilities.periodEndInWb,
          periodEndOutWb:this.liabilities.periodEndOutWb,
          endCount:this.liabilities.endCount,
        }
        let qyTotal = {
          subjectName :"权益合计",
          periodBeginIn:this.interests.periodBeginIn,
          periodBeginOut:this.interests.periodBeginOut,
          periodBeginInWb:this.interests.periodBeginInWb,
          periodBeginOutWb:this.interests.periodBeginOutWb,
          beginCount:this.interests.beginCount,
          periodInt:this.interests.periodInt,
          periodOut:this.interests.periodOut,
          periodIntWb:this.interests.periodIntWb,
          periodOutWb:this.interests.periodOutWb,
          inCount:this.interests.inCount,
          outCount:this.interests.outCount,
          yearInt:this.interests.yearInt,
          yearOut:this.interests.yearOut,
          yearIntWb:this.interests.yearIntWb,
          yearOutWb:this.interests.yearOutWb,
          periodEndIn:this.interests.periodEndIn,
          periodEndOut:this.interests.periodEndOut,
          periodEndInWb:this.interests.periodEndInWb,
          periodEndOutWb:this.interests.periodEndOutWb,
          endCount:this.interests.endCount,
        }
        let cbTotal = {
          subjectName :"成本合计",
          periodBeginIn:this.cost.periodBeginIn,
          periodBeginOut:this.cost.periodBeginOut,
          periodBeginInWb:this.cost.periodBeginInWb,
          periodBeginOutWb:this.cost.periodBeginOutWb,
          beginCount:this.cost.beginCount,
          periodInt:this.cost.periodInt,
          periodOut:this.cost.periodOut,
          periodIntWb:this.cost.periodIntWb,
          periodOutWb:this.cost.periodOutWb,
          inCount:this.cost.inCount,
          outCount:this.cost.outCount,
          yearInt:this.cost.yearInt,
          yearOut:this.cost.yearOut,
          yearIntWb:this.cost.yearIntWb,
          yearOutWb:this.cost.yearOutWb,
          periodEndIn:this.cost.periodEndIn,
          periodEndOut:this.cost.periodEndOut,
          periodEndInWb:this.cost.periodEndInWb,
          periodEndOutWb:this.cost.periodEndOutWb,
          endCount:this.cost.endCount,
        }
        let syTotal = {
          subjectName :"损益合计",
          periodBeginIn:this.profitAndLoss.periodBeginIn,
          periodBeginOut:this.profitAndLoss.periodBeginOut,
          periodBeginInWb:this.profitAndLoss.periodBeginInWb,
          periodBeginOutWb:this.profitAndLoss.periodBeginOutWb,
          beginCount:this.profitAndLoss.beginCount,
          periodInt:this.profitAndLoss.periodInt,
          periodOut:this.profitAndLoss.periodOut,
          periodIntWb:this.profitAndLoss.periodIntWb,
          periodOutWb:this.profitAndLoss.periodOutWb,
          inCount:this.profitAndLoss.inCount,
          outCount:this.profitAndLoss.outCount,
          yearInt:this.profitAndLoss.yearInt,
          yearOut:this.profitAndLoss.yearOut,
          yearIntWb:this.profitAndLoss.yearIntWb,
          yearOutWb:this.profitAndLoss.yearOutWb,
          periodEndIn:this.profitAndLoss.periodEndIn,
          periodEndOut:this.profitAndLoss.periodEndOut,
          periodEndInWb:this.profitAndLoss.periodEndInWb,
          periodEndOutWb:this.profitAndLoss.periodEndOutWb,
          endCount:this.profitAndLoss.endCount,
        }
        let gtlTotal = {}
        // 全科目的共同类
        if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
          gtlTotal = {
            subjectName :"共同类合计",
            periodBeginIn:this.gtl.periodBeginIn,
            periodBeginOut:this.gtl.periodBeginOut,
            periodBeginInWb:this.gtl.periodBeginInWb,
            periodBeginOutWb:this.gtl.periodBeginOutWb,
            beginCount:this.gtl.beginCount,
            periodInt:this.gtl.periodInt,
            periodOut:this.gtl.periodOut,
            periodIntWb:this.gtl.periodIntWb,
            periodOutWb:this.gtl.periodOutWb,
            inCount:this.gtl.inCount,
            outCount:this.gtl.outCount,
            yearInt:this.gtl.yearInt,
            yearOut:this.gtl.yearOut,
            yearIntWb:this.gtl.yearIntWb,
            yearOutWb:this.gtl.yearOutWb,
            periodEndIn:this.gtl.periodEndIn,
            periodEndOut:this.gtl.periodEndOut,
            periodEndInWb:this.gtl.periodEndInWb,
            periodEndOutWb:this.gtl.periodEndOutWb,
            endCount:this.gtl.endCount,
          }
        }
        let zcTotalIndex = 0
        let fzTotalIndex = 0
        let qyTotalIndex = 0
         // 共同类
         let gtlTotalIndex = 0
        let cbTotalIndex = 0
        if(this.comKj == '民办非'){
          qyTotal.subjectName = '净资产合计';
          cbTotal.subjectName = '收入合计';
          syTotal.subjectName = '费用合计'
        }
        // 余额为0不显示的时候以及单看一个科目的时候不要合计数显示出来
        if(!this.listQuery.not0 && !this.listQuery.jeNot0 && !this.listQuery.fseNot0 && !this.listQuery.name && !this.listQuery.beginTime && !this.listQuery.endTime && this.comKj != '民办非'  && this.comKj != '一般企业会计准则(已执行新金融准则)(全科目)' && this.comKj != '一般企业会计准则(全科目)'&& !this.listQuery.subjectCodeStart && !this.listQuery.subjectIdEnd && this.listQuery.wb.length == 0){
          for (let i = 0; i < this.showList.length; i++) {
            if(this.showList[i].subjectCode == '2001' && this.showList[i].id != 0 ){
              zcTotalIndex = i;
            }else if(this.showList[i].subjectCode == '3001' && this.showList[i].id != 0){
              fzTotalIndex = i+1;
            }else if(this.showList[i].subjectCode == '4001' && this.showList[i].id != 0){
              qyTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '5001' && this.showList[i].id != 0){
              cbTotalIndex = i+3;
            }
          }
          this.showList.splice(zcTotalIndex,0,zcTotal)
          this.showList.splice(fzTotalIndex,0,fzTotal)
          this.showList.splice(qyTotalIndex,0,qyTotal)
          this.showList.splice(cbTotalIndex,0,cbTotal)
          this.showList.splice(this.showList.length,0,syTotal)
        }else if (!this.listQuery.not0 && !this.listQuery.jeNot0 && !this.listQuery.fseNot0 && !this.listQuery.name && !this.listQuery.beginTime && !this.listQuery.endTime && this.comKj == '民办非' && !this.listQuery.subjectCodeStart && !this.listQuery.subjectIdEnd){
          for (let i = 0; i < this.showList.length; i++) {
            if(this.showList[i].subjectCode == '2101' && this.showList[i].id != 0 ){
              zcTotalIndex = i;
            }else if(this.showList[i].subjectCode == '3101' && this.showList[i].id != 0){
              fzTotalIndex = i+1;
            }else if(this.showList[i].subjectCode == '4101' && this.showList[i].id != 0){
              qyTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '5101' && this.showList[i].id != 0){
              cbTotalIndex = i+3;
            }
          }
          this.showList.splice(zcTotalIndex,0,zcTotal)
          this.showList.splice(fzTotalIndex,0,fzTotal)
          this.showList.splice(qyTotalIndex,0,qyTotal)
          this.showList.splice(cbTotalIndex,0,cbTotal)
          this.showList.splice(this.showList.length,0,syTotal)
        }else if (!this.listQuery.not0 && !this.listQuery.jeNot0 && !this.listQuery.fseNot0 && !this.listQuery.name && !this.listQuery.beginTime && !this.listQuery.endTime && (this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)'|| this.comKj == '一般企业会计准则(全科目)') && !this.listQuery.subjectCodeStart && !this.listQuery.subjectIdEnd){
          for (let i = 0; i < this.showList.length; i++) {
            if(this.showList[i].subjectCode == '2001' && this.showList[i].id != 0 ){
              zcTotalIndex = i;
            }else if(this.showList[i].subjectCode == '3001' && this.showList[i].id != 0){
              fzTotalIndex = i+1;
            }else if(this.showList[i].subjectCode == '4001' && this.showList[i].id != 0){
              gtlTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '5001' && this.showList[i].id != 0){
              qyTotalIndex = i+2;
            }else if(this.showList[i].subjectCode == '6001' && this.showList[i].id != 0){
              cbTotalIndex = i+3;
            }
          }
          this.showList.splice(zcTotalIndex,0,zcTotal)
          this.showList.splice(fzTotalIndex,0,fzTotal)
          this.showList.splice(qyTotalIndex,0,qyTotal)
          this.showList.splice(cbTotalIndex,0,cbTotal)
          // if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
            this.showList.splice(gtlTotalIndex,0,gtlTotal)
          // }
          this.showList.splice(this.showList.length,0,syTotal)
        }
        this.setScroll()

      })
    },
    printingA5() {
      if(!this.listQuery.beginTime && !this.listQuery.endTime){
        if(this.listQuery.initPeriod && !this.existOld){
          this.$qzfMessage('初始账期仅供查看，不可打印',1)
          return
        }
      }
      this.$refs.printVouch.init('','','',this.wbstatus)
      //this.$refs.printVouch.init()
    },
    daochu() {
      if(!this.listQuery.beginTime && !this.listQuery.endTime){
        if(this.listQuery.initPeriod && !this.existOld){
          this.$qzfMessage('初始账期仅供查看，不可导出',1)
          return
        }
      }
      this.$refs.exportFile.init(this.wbstatus)
    },
    printing() {
      let params = {
        query: this.listQuery,
        bookNames: ['book_subject_balance'],
        comIds:[this.listQuery.comId],
        paperType: "A4-H"
      }
      if(this.listQuery.endTime<this.listQuery.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
      }else{
        accountBook(params).then(res=>{
          if(res.data.msg == 'success'){
            if (res.data.data.msg) {
            this.$qzfMessage(res.data.data.msg);
            } else {
              window.open(res.data.data.path);
            }
          }
        })
      }
    },
    saveZcfz() {
      this.loading = true
      this.showList.map(v=>{
        v.periodBeginIn = Number(v.periodBeginIn)
        v.periodBeginOut = Number(v.periodBeginOut)
        v.periodInt = Number(v.periodInt)
        v.periodOut = Number(v.periodOut)
        v.yearInt = Number(v.yearInt)
        v.yearOut = Number(v.yearOut)
        v.periodEndIn = Number(v.periodEndIn)
        v.periodEndOut = Number(v.periodEndOut)
        v.periodBeginInWb = Number(v.periodBeginInWb)
        v.periodBeginOutWb = Number(v.periodBeginOutWb)
        v.periodIntWb = Number(v.periodIntWb)
        v.periodOutWb = Number(v.periodOutWb)
        v.yearIntWb = Number(v.yearIntWb)
        v.yearOutWb = Number(v.yearOutWb)
        v.beginCount = Number(v.beginCount)
      })
      let fzhsList = []
      this.showList.map(v => {
        if(v.remark == '辅助核算'){
          fzhsList.push(v)
        }
      })
      let str = "" 
      let param = this.showList;
      for (var i = 0; i < param.length; i++) {
        if(this.comKj == '民办非'){
          if (param[i].subjectName == '资产合计' || param[i].subjectName == '负债合计' || param[i].subjectName == '净资产合计' || param[i].subjectName == '收入合计'|| param[i].subjectName == '费用合计' || param[i].remark == '辅助核算') {
          param.splice(i, 1);
          i--;
        }
        }else if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
          if (param[i].subjectName == '资产合计' || param[i].subjectName == '负债合计'|| param[i].subjectName == '共同类合计' || param[i].subjectName == '权益合计' || param[i].subjectName == '成本合计'|| param[i].subjectName == '损益合计' || param[i].remark == '辅助核算') {
            param.splice(i, 1);
            i--;
          }
        }
        else{
          if (param[i].subjectName == '资产合计' || param[i].subjectName == '负债合计' || param[i].subjectName == '权益合计' || param[i].subjectName == '成本合计'|| param[i].subjectName == '损益合计' || param[i].remark == '辅助核算') {
          param.splice(i, 1);
          i--;
        }
        }
      
      }
      param.map(v=>{
        let codeStr = this.findDirectSubordinateAmount(v, param);
        if (codeStr != "") {
          str = codeStr
        }
      })
      if(str != ""){
        this.loading = false
        this.$message.error(str + "下级余额不等于此科目");
        return
      }
      // 保存除去辅助核算
      let saveList = []
      this.showList.map(v => {
        if(!v.remark){
          saveList.push(v)
        }
      })
      subjectBalanceSave({
        list:saveList,
        fzhsList:fzhsList
        }).then(res => {
          this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功", 3)
          this.getList()
        }
      })
    },
    // 科目限制
    findDirectSubordinateAmount(codeBalance,subjects){
      let code = codeBalance.subjectCode
      let codeLen = this.SubjectLens(code)
      let status = false // 是否存在下级
      let amountBeginIn = 0.0
      let amountBeginOut = 0.0
      let amountYearIn = 0.0
      let amountYearOut = 0.0
      subjects.map(subject=>{
        if(subject.subjectCode.length > code.length && (codeLen+1) == this.SubjectLens(subject.subjectCode) && code + "." == subject.subjectCode.slice(0,code.length + 1)){
          status = true
          amountBeginIn = (amountBeginIn*1 + subject.periodBeginIn*1).toFixed(2)
          amountBeginOut = (amountBeginOut*1 + subject.periodBeginOut*1).toFixed(2)
          amountYearIn = (amountYearIn*1 + subject.yearInt*1).toFixed(2)
          amountYearOut = (amountYearOut*1 + subject.yearOut*1).toFixed(2)
        }
      })
      if (status) {
        if (codeBalance.periodBeginIn != 0) {
          amountBeginIn = (amountBeginIn*1 - amountBeginOut*1).toFixed(2)  
          amountBeginOut = 0
        } else {
          amountBeginOut = (amountBeginOut*1 - amountBeginIn*1).toFixed(2)
          amountBeginIn = 0
        }
        if (codeBalance.periodBeginIn != amountBeginIn || codeBalance.periodBeginOut != amountBeginOut || codeBalance.yearInt != amountYearIn || codeBalance.yearOut != amountYearOut) {
          return codeBalance.subjectCode
        }
      }
      return ""
    },
    SubjectLens(code){
      return code.split('.').length
    },
    // 统计
    sumsAlert() {
      let str = `<table style="border:1px solid #c2c2c2;" class="content_table" cellspacing="0" width="100%">
            <tr style="background:var(--themeColor,#17a2b8);color:#ffffff;font-weight:600;line-height:32px;">
              <td rowspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:12%">类型</td>
              <td colspan="2"  style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">期初余额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本期发生额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本年累计发生额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;text-align: center;width:22%">期末余额</td>
            </tr>
            <tr style="line-height:28px;background:var(--themeColorLeftMenu,#ecf7f9);">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
            </tr>
            <tr style="line-height:28px;">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;background:var(--themeColorLeftMenu,#ecf7f9);">资产</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;background:var(--themeColorLeftMenu,#ecf7f9);">负债</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;background:var(--themeColorLeftMenu,#ecf7f9);">${this.comKj == '民办非'?'净资产':'权益'}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;background:var(--themeColorLeftMenu,#ecf7f9);">${this.comKj == '民办非'?'收入':'成本'}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;background:var(--themeColorLeftMenu,#ecf7f9);">${this.comKj == '民办非'?'费用':'损益'}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndOut}</td>
            </tr>
            
          </table>`
          let strGtl = ''
          if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
            strGtl = `<table style="border:1px solid #c2c2c2;" class="content_table" cellspacing="0" width="100%">
              <tr style="background:#f6f6f6;color:#333;font-weight:600;line-height:32px;">
                <td rowspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:12%">类型</td>
                <td colspan="2"  style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">期初余额</td>
                <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本期发生额</td>
                <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本年累计发生额</td>
                <td colspan="2" style="border-bottom:1px solid #c2c2c2;text-align: center;width:22%">期末余额</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">资产</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndOut}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">负债</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndOut}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">共同类</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.gtl.periodEndOut}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.comKj == '民办非'?'净资产':'权益'}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndOut}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.comKj == '民办非'?'收入':'成本'}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndOut}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.comKj == '民办非'?'费用':'损益'}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearInt}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearOut}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndIn}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndOut}</td>
              </tr>
              
            </table>`
          }
          if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
            this.str = strGtl
          }else{
            this.str = str
          }
          this.dialogVisible = true
      // this.$alert(str, '数额统计', {
      //   dangerouslyUseHTMLString: true,
      //   customClass:'message_box_alert'
      // });
    },
    handleFilter() {
      this.visible = false
      this.getList()
    },
    getSummaries(param){
      let { columns, data } = param;
      let periodBeginIn = 0
      let periodBeginOut = 0
      let periodInt = 0
      let periodOut = 0
      let yearInt = 0
      let yearOut = 0

      let periodBeginInWb = 0
      let periodBeginOutWb = 0
      let periodIntWb = 0
      let periodOutWb = 0
      let yearIntWb = 0
      let yearOutWb = 0

      let periodEndIn = 0
      let periodEndOut = 0
      let periodEndInWb = 0
      let periodEndOutWb = 0
      data = this.showList
      data.map(v=>{
        if(this.comKj == '民办非'){
          if((v.subjectName != '资产合计' && v.subjectName != '负债合计'  && v.subjectName != '净资产合计' && v.subjectName != '收入合计' && v.subjectName != '费用合计') && v.subjectCode.length == 4 && v.remark != '辅助核算' ){
            if(v.periodEndIn != 0){
            }
            periodBeginIn +=  Number(v.periodBeginIn)
            periodBeginOut += Number(v.periodBeginOut)
            periodInt += Number(v.periodInt)
            periodOut += Number(v.periodOut)
            periodEndIn += Number(v.periodEndIn)
            periodEndOut += Number(v.periodEndOut)
            yearInt += Number(v.yearInt)
            yearOut += Number(v.yearOut)

            periodBeginInWb += Number(v.periodBeginInWb)
            periodBeginOutWb += Number(v.periodBeginOutWb)
            periodIntWb += Number(v.periodIntWb)
            periodOutWb += Number(v.periodOutWb)
            yearIntWb += Number(v.yearIntWb)
            yearOutWb += Number(v.yearOutWb)
            periodEndInWb += Number(v.periodEndInWb)
            periodEndOutWb += Number(v.periodEndOutWb)
          }
        }else if(this.comKj == '一般企业会计准则(已执行新金融准则)(全科目)' || this.comKj == '一般企业会计准则(全科目)'){
          if((v.subjectName != '资产合计' && v.subjectName != '负债合计'  && v.subjectName != '权益合计' && v.subjectName != '共同类合计' && v.subjectName != '成本合计' && v.subjectName != '损益合计') && v.subjectCode.length == 4 && v.remark != '辅助核算' ){
              if(v.periodEndIn != 0){
              }
              periodBeginIn +=  Number(v.periodBeginIn)
              periodBeginOut += Number(v.periodBeginOut)
              periodInt += Number(v.periodInt)
              periodOut += Number(v.periodOut)
              periodEndIn += Number(v.periodEndIn)
              periodEndOut += Number(v.periodEndOut)
              yearInt += Number(v.yearInt)
              yearOut += Number(v.yearOut)

            periodBeginInWb += Number(v.periodBeginInWb)
            periodBeginOutWb += Number(v.periodBeginOutWb)
            periodIntWb += Number(v.periodIntWb)
            periodOutWb += Number(v.periodOutWb)
            yearIntWb += Number(v.yearIntWb)
            yearOutWb += Number(v.yearOutWb)
            periodEndInWb += Number(v.periodEndInWb)
            periodEndOutWb += Number(v.periodEndOutWb)
            // if(v.type == 1){
            //   periodEndIn = periodEndIn + v.periodBeginIn - v.periodBeginOut + v.periodInt - v.periodOut
            //   periodEndInWb = periodEndInWb + v.periodBeginInWb - v.periodBeginOutWb + v.periodIntWb - v.periodOutWb
            // }else{
            //   periodEndOut = periodEndOut - v.periodBeginIn + v.periodBeginOut - v.periodInt + v.periodOut
            //   periodEndOutWb = periodEndOutWb - v.periodBeginInWb + v.periodBeginOutWb - v.periodIntWb + v.periodOutWb
            // }
          }
        }else{
          if((v.subjectName != '资产合计' && v.subjectName != '负债合计'  && v.subjectName != '权益合计' && v.subjectName != '成本合计' && v.subjectName != '损益合计') && v.subjectCode.length == 4 && v.remark != '辅助核算' ){
            if(v.periodEndIn != 0){
            }
            periodBeginIn +=  Number(v.periodBeginIn)
            periodBeginOut += Number(v.periodBeginOut)
            periodInt += Number(v.periodInt)
            periodOut += Number(v.periodOut)
            periodEndIn += Number(v.periodEndIn)
            periodEndOut += Number(v.periodEndOut)
            yearInt += Number(v.yearInt)
            yearOut += Number(v.yearOut)

            periodBeginInWb += Number(v.periodBeginInWb)
            periodBeginOutWb += Number(v.periodBeginOutWb)
            periodIntWb += Number(v.periodIntWb)
            periodOutWb += Number(v.periodOutWb)
            yearIntWb += Number(v.yearIntWb)
            yearOutWb += Number(v.yearOutWb)
            periodEndInWb += Number(v.periodEndInWb)
            periodEndOutWb += Number(v.periodEndOutWb)
          }
        }
       
      })
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "periodBeginIn"){
          sums[index] = this.$comdify(periodBeginIn.toFixed(2))
        }else if(column.property == "periodBeginOut"){
          sums[index] = this.$comdify(periodBeginOut.toFixed(2))
        }else if(column.property == "periodInt"){
          sums[index] = this.$comdify(periodInt.toFixed(2))
        }else if(column.property == "periodOut"){
          sums[index] = this.$comdify(periodOut.toFixed(2))
        }else if(column.property == "yearInt"){
          sums[index] = this.$comdify(yearInt.toFixed(2))
        }else if(column.property == "yearOut"){
          sums[index] = this.$comdify(yearOut.toFixed(2))
        }else if(column.property == "periodBeginInWb"){
          sums[index] = this.$comdify(periodBeginInWb.toFixed(2))
        }else if(column.property == "periodBeginOutWb"){
          sums[index] = this.$comdify(periodBeginOutWb.toFixed(2))
        }else if(column.property == "periodIntWb"){
          sums[index] = this.$comdify(periodIntWb.toFixed(2))
        }else if(column.property == "periodOutWb"){
          sums[index] = this.$comdify(periodOutWb.toFixed(2))
        }else if(column.property == "yearIntWb"){
          sums[index] = this.$comdify(yearIntWb.toFixed(2))
        }else if(column.property == "yearOutWb"){
          sums[index] = this.$comdify(yearOutWb.toFixed(2))
        }else if(column.property == "periodEndIn"){
          sums[index] = this.$comdify(periodEndIn.toFixed(2))
        }else if(column.property == "periodEndOut"){
          sums[index] = this.$comdify(periodEndOut.toFixed(2))
        }else if(column.property == "periodEndInWb"){
          sums[index] = this.$comdify(periodEndInWb.toFixed(2))
        }else if(column.property == "periodEndOutWb"){
          sums[index] = this.$comdify(periodEndOutWb.toFixed(2))
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },

    // 历史
    tongbu(){
      this.$confirm('确认同步科目', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        oldTongbuSubject({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功")
          }
        })
      })
    },
    tongbu2(){
      this.$confirm('确认同步科目余额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        oldNewSubjectSync({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功")
          }
        })
      })
    },
    tiaozhuan(row){
      if(!row.subjectCode) return
      this.$emit('success',row,this.listQuery.period)
    },
    //同步正常账期科目余额表
    tongbuNormal(){
      this.$confirm('确认同步科目余额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        linkAgeAll({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功");
            this.getList()
          }
        })
      })
    },
    // 改变余额上级变
    changeBalancePi(row,p){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += (Number(this.showList[i].periodBeginIn) - Number(this.showList[i].periodBeginOut))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += (Number(this.showList[i].periodBeginOut) - Number(this.showList[i].periodBeginIn))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
          this.changeBalancePi(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
          this.changeBalancePi(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
        }
      })
    },
    changeBalancePo(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          // result += Number(this.showList[i].periodBeginOut)
          result += (Number(this.showList[i].periodBeginIn) - Number(this.showList[i].periodBeginOut))
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += (Number(this.showList[i].periodBeginOut) - Number(this.showList[i].periodBeginIn))
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
          this.changeBalancePo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginIn = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
          this.changeBalancePo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOut = result.toFixed(2)
        }
      })
    },
    changeBalanceYi(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].yearInt) - Number(this.showList[i].yearOut)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].yearOut)- Number(this.showList[i].yearInt)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.yearInt = result.toFixed(2)
          this.changeBalanceYi(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.yearInt = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.yearOut = result.toFixed(2)
          this.changeBalanceYi(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.yearOut = result.toFixed(2)
        }
      })
    },
    changeBalanceYo(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].yearInt) - Number(this.showList[i].yearOut)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].yearOut) - Number(this.showList[i].yearInt)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.yearInt = result.toFixed(2)
          this.changeBalanceYo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.yearInt = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.yearOut = result.toFixed(2)
          this.changeBalanceYo(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.yearOut = result.toFixed(2)
        }
      })
    },
    // 外币的借贷方
    // 改变余额上级变
    changeBalancePiWb(row,p){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].periodBeginInWb) - Number(this.showList[i].periodBeginOutWb)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].periodBeginOutWb) - Number(this.showList[i].periodBeginInWb)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
          this.changeBalancePiWb(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
          this.changeBalancePiWb(v,row)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
        }
      })
    },
    changeBalancePoWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].periodBeginInWb) - Number(this.showList[i].periodBeginOutWb)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].periodBeginOutWb) - Number(this.showList[i].periodBeginInWb)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
          this.changeBalancePoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.periodBeginInWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
          this.changeBalancePoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.periodBeginOutWb = result.toFixed(2)
        }
      })
    },
    changeBalanceYiWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].yearIntWb) - Number(this.showList[i].yearOutWb)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].yearOutWb) - Number(this.showList[i].yearIntWb)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.yearIntWb = result.toFixed(2)
          this.changeBalanceYiWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.yearIntWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.yearOutWb = result.toFixed(2)
          this.changeBalanceYiWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.yearOutWb = result.toFixed(2)
        }
      })
    },
    changeBalanceYoWb(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) { 
        if(this.showList[i].parentId == row.parentId && row.type == 1){
          result += Number(this.showList[i].yearIntWb) - Number(this.showList[i].yearOutWb)
        }else if(this.showList[i].parentId == row.parentId && row.type == 2){
          result += Number(this.showList[i].yearOutWb) - Number(this.showList[i].yearIntWb)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 1){
          v.yearIntWb = result.toFixed(2)
          this.changeBalanceYoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 1){
          v.yearIntWb = result.toFixed(2)
        }else if(v.subjectId == row.parentId && !v.remark && !row.remark && row.type == 2){
          v.yearOutWb = result.toFixed(2)
          this.changeBalanceYoWb(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark && row.type == 2){
          v.yearOutWb = result.toFixed(2)
        }
      })
    },
    // 数量的计算
    changeBalanceCount(row){
      let result= 0
      for (let i = 0; i < this.showList.length; i++) {
        if(this.showList[i].parentId == row.parentId){
          result += Number(this.showList[i].beginCount)
        }
      }
      this.showList.map(v => {
        if(v.subjectId == row.parentId && !v.remark && !row.remark){
          v.beginCount = result.toFixed(2)
          this.changeBalanceCount(v)
        }else if(v.subjectCode == row.subjectCode && v.subjectId && !v.parentId && row.remark){
          v.beginCount = result.toFixed(2)
        }
      })
    },

    // 数量的小数位
    comdify(n) {
      if(!n) return n;
      n = n.toString()
      let str = n.split('.');
      let re = /\d{1,3}(?=(\d{3})+$)/g;
      let n1 = str[0].replace(re, "$&,");
      return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
    },
    //重置
    cancel(){
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        initPeriod: "",
        not0: true,
        fzhsStatus:false,
        name: '',
        level: 3,
        beginTime:'',
        endTime:'',
        yearAmount:false,
        fseNot0:false,
        wb:[]
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  .left_box{
    float: left;
    width: 70%;
    margin-bottom: 10px;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.styleForm{
  .el-select{
    margin: 0px;
  }
}
.bottom_btns{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
    span{
      color: #F15A24;
    }
  }
}
.strTdStyle{
  width:1%;
  border: 1px solid #b3aeae;
  height: 28px;
}

span{
  font-size: 12px;
}
.bianma {
  cursor: pointer;
  &.bianma-p {
    font-weight: 600
  }
  &.bianma-c {
    padding-left:10px
  }
}
.subject-name {
  &.subjectName-one {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  &.subjectName-two {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
    padding-left:10px;
  }
  &.subjectName-three {
   
    cursor: pointer;
    font-weight: 600;
    font-size:13px;
    color:var(--themeColor,#17a2b8);
  }
}
.period-begin-in {
  &.periodBeginIn-one {
    font-weight:600
  }
  &.periodBeginIn-three {
    color:var(--themeColor,#17a2b8);
    font-size:13px;font-weight:600;
  }
}
.periodBeginInWb{
  color:var(--themeColor,#17a2b8);font-size:13px;font-weight:600;
}
.pad-r-12 {
  padding-right: 12px;
}
</style>
<style>

.message_box_alert{
  width: 800px !important;
}
</style>
<style lang="scss">

.styleForm{
  .el-input{
    width: 136px;
  }
}
.el-table__header,.el-table__body,.el-table__footer{
   width: 100%;
   table-layout: fixed !important;
}
.xginput {
  border: 1px solid #b3aeae;
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: #606266;
  outline: none;
  padding: 0 10px;
}

</style>