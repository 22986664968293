<template>
  <div style="position: relative;">
    <div class="top_btns clearfix">
      <div class="left_box">
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;width:430px" label-width="120px">
            <el-form-item label="选择期间：">
              <qzf-period v-model:period="listQuery.period" class="period_style"></qzf-period>
            </el-form-item>
            <el-form-item label="科目编码：" >
              <subject-list-all v-model:subjectId="listQuery.subjectId" :codes="$findCode(code)"></subject-list-all>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <!-- <qzf-button jz="false" @click="printing()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button> -->
        <qzf-button jz="false" @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>
    <div class="fixed_box_table" :style="{height:contentStyleObj}">
      <div class="tableDl" v-for="(item,index) in list" :key="index" >
        <el-table stripe :data="item.Vouchers" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" border fit highlight-current-row  >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column label="账期" align="center" width="110">
            <template #default="{row}">
              <span>{{ row.period }}</span>
            </template>
          </el-table-column>
          <el-table-column label="日期" align="center" width="110">
            <template #default="{row}">
              <span v-if="row.voucherDate">{{$parseTime(row.voucherDate, "{y}-{m}-{d}")}}</span>
            </template>
          </el-table-column>
          <el-table-column label="凭证号码" align="center" width="200">
            <template #default="{row}">
              <span>{{ row.voucherNo }}</span>
            </template>
          </el-table-column>
          <el-table-column label="摘要" align="center" width="260">
            <template #default="{row}">
              <span>{{ row.summary }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="item.big.type == '1'?'借方':'贷方'" align="center">
            <el-table-column v-for="(subject,index2) in item.subjectLower" :key="index2" prop="periodInt" :label="subject.subjectName" min-width="120" align="center">
              <el-table-column prop="periodInt" :label="subject.type == '1'?subject.periodInt+'':subject.periodOut+''" min-width="120" align="center">
              <template #default="scope">
                <span v-if="subject.subjectId == scope.row.subjectId && item.big.type == 1">{{$comdify(scope.row.inAmount) }}</span>
                <span v-if="subject.subjectId == scope.row.subjectId && item.big.type != 1">{{$comdify(scope.row.outAmount) }}</span>
              </template>
            </el-table-column>
            </el-table-column>
          </el-table-column>
          <!-- <el-table-column :label="item.big.type == 0?'贷方':'借方'" align="center">
            <el-table-column v-for="(subject,index2) in item.subjectLower" :key="index2" prop="periodInt" :label="subject.subjectName" min-width="120" align="center">
              <el-table-column prop="periodInt" :label="subject.type == 1?subject.periodInt:subject.periodOut" min-width="120" align="center">
              <template #default="scope">
                <span v-if="subject.subjectId == scope.row.subjectId && item.big.type == 1">{{scope.row.inAmount }}</span>
                <span v-if="subject.subjectId == scope.row.subjectId && item.big.type != 1">{{scope.row.outAmount }}</span>
              </template>
            </el-table-column>
            </el-table-column>
          </el-table-column> -->

          <!-- <el-table-column :label="item.big.type == 0?'借方':'贷方'" align="center" width="200">
            <template #default="{row}">
              <span>{{row.amount }}</span>
            </template>
          </el-table-column> -->
          <el-table-column :label="item.big.type == '1'?'贷方':'借方'" align="center" width="200">
            <template #default="{row}">
              <span>{{$comdify(row.amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" width="200">
            <template #default="{row}">
              <span>{{$comdify(row.endAmount)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    

    <exportFile ref="exportFile" name="book_multi_column" :listQuery1="this.listQuery"></exportFile>

  </div>
</template>

<script>
import { multiColumnList } from "@/api/book.js"
import exportFile from "./exportFile.vue"
import SubjectListAll from '../../../components/subject/subjectListAll.vue'

export default {
  name:'detailAccount',
  components: {
    exportFile,
    SubjectListAll,
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      listLoading: false,
      list: [{big:{}}],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        subjectCode: '',
        level: 1,
        rq:[]
      },
      code: ['code4101','code4001','code4002','code4301','code5001','code5051','code5301','code5401','code5402','code5403','code5601','code5602','code5603'],

    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(190)
    this.listQuery.subjectCode = '5602'
    // this.getList()
  },
  methods: {
    getList(){
      this.listLoading = true
      multiColumnList(this.listQuery).then(res=>{
        let list = res.data.data.info
        this.listLoading = false
        list.map(item=>{
          item.Vouchers.unshift({
            inAmount:0,
            outAmount:0,
            period: item.big.period,
            summary: "期初余额",
            endAmount: item.big.type == 1?item.big.periodBeginIn:item.big.periodBeginOut,
          })
          let amount = item.big.periodBeginIn
          if(item.big.type != 1){
            amount = item.big.periodBeginOut
          }
          item.Vouchers.map(v=>{
            if(item.big.type == 1){
              amount = (amount*1 + v.inAmount*1).toFixed(2)
              v.endAmount = amount
            }else{
              amount = (amount*1 + v.outAmount*1).toFixed(2)
              v.endAmount = amount
            }
          })
          if(item.big.type == 1){
            amount = (amount*1 - item.big.periodOut*1).toFixed(2)
          }else{
            amount = (amount*1 - item.big.periodInt*1).toFixed(2)
          }
          item.Vouchers.push({
            inAmount:0,
            outAmount:0,
            period: item.big.period,
            summary: this.getText(item.big.subjectCode),
            amount: item.big.type == 1?item.big.periodOut:item.big.periodInt,
            endAmount: amount,
          })
        })
        this.list = list
      })
    },
    getText(code){
      if(code == '4101'){//制造费用
        return "转入生产成本"
      }else if(code == '4001'){//生产成本
        return "转入库存商品"
      }else if(code == '4002'){//劳务成本
        return "转入主营业务成本"
      }else if(code == '5403' || code == '5602' || code == '5603' || code == '5601' || code == '5401' || code == '5402' || code == '5001' || code == '5051'){//管理费用 等损益科目
        return "转入本年利润"
      }else if(code == '4301'){//研发支出
        return "转入无形资产"
      }
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    //重置
    cancel(){
      let originCode = this.listQuery.subjectCode
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        subjectCode: originCode,
        level: 1,
        rq:[]
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container{
  h5{
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.top_btns {
   .left_box{
    float: left;
    width: 76%;
  }
  .right_box {
    float: right;
    width: 20%;
    text-align: right;
  }
  .time_box{
    float: left;
  }
  .bottom_btns{
    float: left;
    // margin-left: 20px;
  }
}
.tableDl {
  margin-top: 15px;
}
.period_style{
  margin-left: 0px;
  width: 136px;
}
</style>