<template>
  <div style="position: relative;">
    <div class="top_btns clearfix">
      <div class="left_box">
        <span>账期：</span><qzf-period v-model:period="listQuery.period" @success="getList" style="width: 120px;"></qzf-period>
        <el-input size="small" v-model="listQuery.name" style="width:180px" placeholder="请输入核算名称" clearable></el-input>
        <el-button size="small" icon="Search" type="primary" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList()" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;height:130px;width:400px" class="styleForm">
            <el-form-item label="辅助类别 ：" :label-width="formLabelWidth"> 
              <fzhsSubjectList v-model:fzhsId="listQuery.fzhsId" @initSuccess="getList"></fzhsSubjectList>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.atFlag" label="显示数量金额"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px">
              <el-checkbox v-model="listQuery.numFlag" label="隐藏本年累计发生额"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left: 5px;">
          <el-switch
            v-model="expend"
            active-text="展开全部"
            inactive-text=""
            size="small"
            @change="isUpdate = !isUpdate"
          ></el-switch
        >
        </span>
      </div>
      <div class="right_box">
        <qzf-button jz="false" @click="printingA5()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>

    <el-table show-summary :summary-method="getSummaries" stripe :height="contentStyleObj" :data="showList" border  style="width: 100%;" v-loading="loading" :default-expand-all="expend"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="sortId" :key="isUpdate">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>  
      <el-table-column label="核算编码" min-width="80" align="left">
          <template #default="scope">
            <span v-if="scope.row.sortId">{{scope.row.code}}</span>
          </template>
        </el-table-column>

        <el-table-column label="核算项目名称" min-width="240" align="left">
          <template #default="scope">
            <span v-if="scope.row.sortId" style="font-weight: 600;">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="科目名称" min-width="240" align="left">
          <template #default="scope">
            <span v-if="scope.row.id">{{scope.row.subjectCode}}_{{ scope.row.subjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="期初余额" align="center">
          <el-table-column  align="center" prop="periodBeginIn" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginIn}}</span> -->
              <span>{{ $comdify(scope.row.periodBeginIn)}}</span>
            </template>
          </el-table-column>
          <el-table-column  align="center" prop="periodBeginOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{ $comdify(scope.row.periodBeginOut)}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="listQuery.atFlag" align="center" prop="beginCount" label="数量" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{ this.comdify(scope.row.beginCount)}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="本期发生额" align="center">
          <el-table-column align="center" prop="periodInt" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodInt}}</span> -->
              <span>{{ $comdify(scope.row.periodInt)}}</span>
            </template>
          </el-table-column>  
          <el-table-column align="center" prop="periodOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodOut}}</span> -->
              <span>{{ $comdify(scope.row.periodOut)}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="listQuery.atFlag" align="center" prop="inCount" label="数量借" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{ this.comdify(scope.row.inCount)}}</span>
            </template>
          </el-table-column>

          <el-table-column v-if="listQuery.atFlag" align="center" prop="outCount" label="数量贷" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.periodBeginOut}}</span> -->
              <span>{{ this.comdify(scope.row.outCount)}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="本年累计发生额" align="center" v-if="listQuery.numFlag == false">
          <el-table-column align="center" prop="yearInt" label="借方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.yearInt}}</span> -->
              <span>{{ $comdify(scope.row.yearInt)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="yearOut" label="贷方" width="120">
            <template #default="scope">
              <!-- <span>{{scope.row.yearOut}}</span> -->
              <span>{{ $comdify(scope.row.yearOut)}}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="期末余额" align="center">
          <el-table-column align="center" prop="periodEndIn" label="借方" width="120">
            <template #default="scope">
              <span>{{ $comdify(scope.row.periodEndIn)}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="periodEndOut" label="贷方" width="120">
            <template #default="scope">
              <span>{{ $comdify(scope.row.periodEndOut)}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="listQuery.atFlag" align="center" prop="endCount" label="数量" width="120">
            <template #default="scope">
              <span>{{ this.comdify(scope.row.endCount)}}</span>
            </template>
          </el-table-column>
        </el-table-column>
    </el-table>
  </div>
  <printVouch ref="printVouch" name="book_fzhs_subject" :listQuery1="listQuery"></printVouch>
   <exportFile ref="exportFile" name="book_fzhs_subject" :listQuery1="listQuery"></exportFile>
</template>

<script>
import { fzhsBalanceList  } from "@/api/subject"
import fzhsSubjectList from "./fzhsSubjectList.vue"
import exportFile from "./exportFile.vue"
import printVouch from "./printVouch.vue"


export default {
  name:'fzhsSubjectBalance',
  components :{ fzhsSubjectList ,exportFile ,printVouch},
  data() {
    return {
      showList:[],
      formLabelWidth: '120px',
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: 0,
        not0: false,
        name: '',
        level: 1,
        bookNames: ["book_fzhs_subject"],
        atFlag:false,  //显示本年累计
        numFlag:false  // 显示数量金额
      },
      // yearAmount:false,
      // wbstatus:false,
      loading:false,
      expend:false,
      isUpdate:false
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(200)
    // this.getList()
  },
  methods:{
    getList(){
      this.loading = true
      fzhsBalanceList(this.listQuery).then(res=>{
        this.loading = false
        this.showList = res.data.data.list
        let i = 0
        this.showList.map(v=>{
          v.sortId = i+1
          i++
        })
      })
    },
    daochu() {
      this.$nextTick(()=>{
        this.$refs.exportFile.init()
      })
    },
    printingA5() {
      this.$nextTick(()=>{
        this.$refs.printVouch.init()
      })
    },
    // 数量的小数位
    comdify(n) {
      if(!n) return n;
      n = n.toString()
      let str = n.split('.');
      let re = /\d{1,3}(?=(\d{3})+$)/g;
      let n1 = str[0].replace(re, "$&,");
      return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.0000`;
    },
     //重置
     cancel(){
      let originFzhsId = this.listQuery.fzhsId
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: originFzhsId,
        not0: false,
        name: '',
        level: 1,
        bookNames: ["book_fzhs_subject"],
        atFlag:false,  //显示本年累计
        numFlag:false  // 显示数量金额
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  .left_box{
    float: left;
    width: 70%;
    margin-bottom: 10px;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.styleForm{
  .el-select{
    margin: 0px;
  }
}
span{
  font-size: 12px;
}
</style>