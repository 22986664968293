<template>
  <div>
    <div class="top_btns clearfix">
      <div class="left_box">
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="getLists" style="margin-right: 5px;">搜索</el-button>
        <!-- 筛选 -->
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;height:160px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period"></qzf-period>
            </el-form-item>
            <el-form-item label="辅助类别 ：" :label-width="formLabelWidth"> 
              <fzhsSubjectList v-model:fzhsId="listQuery.fzhsId" @initSuccess="getList"></fzhsSubjectList>
            </el-form-item>
            <!-- <el-form-item label="会计期间 ：" :label-width="formLabelWidth">  
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item> -->
          </el-form>
        </search>
      </div>
      <div  class="right_box">
        <qzf-button jz="false" @click="printAll()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false" @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>

    <el-row>
      <el-col :span="5">
        <div class="center_main">
          <el-input v-model="filterText" placeholder="请输入关键字" style="width:100%;margin-bottom: 10px;" size="small"></el-input>
          <el-tree ref="tree" :style="{height:contentStyleObj1}" class="elTree" :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" node-key="id" style="width:100%;overflow:scroll"></el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div>
          <div style="margin-left:26px;margin-bottom:10px;color:var(--themeColor,#17a2b8);font-weight:600;font-size:15px">辅助核算项目:{{this.fzhsName}} <span v-if="this.fzhsSubjectName != ''">>></span> {{this.fzhsSubjectName}}</div>
          <el-table stripe :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="日期" align="center" min-width="100">
            <template  #default="scope">
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="科目" align="center" min-width="200">
            <template #default="scope">
              <span>{{scope.row.subjectCode}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <template #default="scope">
              <span>{{$comdify(scope.row.inAmount)}}</span>
            </template>
          </el-table-column>


          <el-table-column label="贷方" align="center" min-width="150">
            <template #default="scope">
              <span>{{$comdify(scope.row.outAmount)}}</span>
            </template>
          </el-table-column>

          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>

          <el-table-column label="余额" align="center" min-width="150">
            <template #default="scope">
              <span>{{$comdify(scope.row.endAmount)}}</span>
            </template>
          </el-table-column>

        </el-table>
        </div>
      </el-col>
    </el-row>

    <printVouch ref="printVouch" :name="this.name1" :listQuery1="this.listQuery" :DY="this.DY" :fzList="this.data"></printVouch>
    <exportFile ref="exportFile" name="book_fzhs_detail_account" :listQuery1="this.listQuery" :fzList="this.data"></exportFile>
  </div>
</template>

<script>
import fzhsSubjectList from "./fzhsSubjectList.vue"
import { detailFzhsList , detailFzhs } from "@/api/book.js"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
import { number } from 'echarts'
export default {
  name:'fzhsDetailAccount',
  components:{ fzhsSubjectList,printVouch,exportFile },
  data() {
    return {
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: 0,
        fzhsItemId: 0,
        subjectId:0,
        rq:[],
      },
      formLabelWidth: '120px',
      contentStyleObj:{}, //高度变化
      contentStyleObj1:{}, //高度变化

      filterText: '',
      data:[],
      defaultProps: {
        children: 'items',
        label: 'name',
      },
      list: [],
      fzhsName:'',
      fzhsSubjectName:'',
      name1:'',

    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(270)
    this.contentStyleObj1=this.$getHeight(295)
    this.getList()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods:{
    getLists(){
      this.getList()
    },
    getList(e) {
      console.log(e);
      if(typeof(e) == 'object'){
        this.listQuery.fzhsId = e.fzhs.split('-')[0]*1
      }
      detailFzhsList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.data = res.data.data.list
          this.list = []
          this.fzhsName =''
          this.fzhsSubjectName = ''
          if(typeof(e) == 'object'){
            this.data.map(v => {
              if(v.id == e.fzhs.split('-')[1]*1){
                this.fzhsName = v.name
                v.items.map(vv => {
                  if(e.parentId == vv.id){
                    this.fzhsSubjectName = vv.name3
                  }
                })
              }
            })
          }
        }else{
          this.data = []
        }
      })
      if(typeof(e) == 'object'){
        this.handleNodeClick(e)
      }
    },
    filterNode(value, data,node) {
      if (!value) return true
      let parentNode = node.parent; // 父级node
      let labels = [node.label]; // 当前node的名字
      let level = 1; // 层级
      while (level < node.level) {
        labels = [...labels, parentNode.label]; // 当前node名字，父级node的名字
        parentNode = parentNode.parent;
        level++;
      }
      return labels.some((d) => d.indexOf(value) !== -1);
      // return data.name.indexOf(value) !== -1
    },
    handleNodeClick(data,node,item){
      // 辅助核算的跳转 辅助核算都用的parentId
      if(data.remark == "辅助核算"){
        this.listQuery.fzhsId = data.fzhs.split('-')[0]*1
        this.listQuery.fzhsItemId = data.fzhs.split('-')[1]*1
        this.listQuery.subjectId = data.parentId
      }else if(node.childNodes.length !=0){
        this.fzhsName = node.data.name
        this.fzhsSubjectName = ''
        this.listQuery.fzhsItemId = node.data.id
        this.listQuery.subjectId = 0
      }else{
        this.fzhsName = node.parent.data.name
        this.fzhsSubjectName = node.data.name3
        this.listQuery.fzhsItemId = node.parent.data.id
        this.listQuery.subjectId = node.data.id
      }
      detailFzhs(this.listQuery).then(res=>{
        if(res.data.data.info){
          this.list = res.data.data.info
        }else{
          this.list = []
        }  
      })
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    printAll(e) {
      this.DY = e
      this.name1 = 'book_fzhs_detail_account',
      this.$nextTick(()=>{
        this.$refs.printVouch.init()
      })
    },
    xx(period, list){
      let s = ""
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        if(element.label != '初始账期'){
          for (let index = 0; index < element.options.length; index++) {
            let element2 = element.options[index];
            if(element2.period == period){
              s = element.label
            }
          }
        } 
      }
      return s
    },
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      let ops = this.$store.getters['commons/periods'];
      let lll = this.xx(row.period,ops)
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      if(lll != "正常账期"){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: row.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
     //重置
     cancel(){
      let originFzhsId = this.listQuery.fzhsId
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        fzhsId: originFzhsId,
        fzhsItemId: 0,
        subjectId:0,
        rq:[],
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchGjz {
  border: 1px solid #ebeef5;
}
.top_btns {
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.center_main{
  width: 100%;
  height:100%;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 20px; 
}
.elTree {
  width: 100%;
  height: 500px;
  overflow: scroll;
  // box-shadow: rgb(219 211 211 / 58%) 5px 8px 8px 3px;
  border: 1px solid #ede5e5;
}
::-webkit-scrollbar {
    width: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
}
</style>