<template>
  <el-select v-model="modelValue" :placeholder="placeholder" class="select-container" filterable @change="changeValue">
    <div v-if="type == 'qr'">
      <el-option v-for="item in qrOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </div>
    <div v-else-if="type == 'zj'">
      <el-option v-for="item in zjOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </div>
    <div v-else-if="type == 'skqr'">
      <el-option v-for="item in skqrOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </div>
    <div v-else>
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        <span v-if="item.value == 2">{{ loadingText }}</span>
        <span v-else>{{ item.label }}</span>
      </el-option>
    </div>
  </el-select>
</template>

<script>
export default {
  name: "autoHomeSelect",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    loadingText: {
      type: String,
      default: "进行中",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modelValue: "",
      options: [
        {
          label: "全部",
          value: 88,
        },
        {
          label: "未开始",
          value: 0,
        },
        {
          label: "进行中",
          value: 1,
        },
        {
          label: "执行中",
          value: 2,
        },
        {
          label: "成功",
          value: 3,
        },
        {
          label: "失败",
          value: 4,
        },
        {
          label: "部分成功",
          value: 5,
        },
        {
          label: "无需操作",
          value: 6,
        },
        {
          label: "已跳过",
          value: 7,
        },
      ],
      qrOptions: [
        {
          label: "全部",
          value: 88,
        },
        {
          label: "未确认",
          value: 0,
        },
        {
          label: "待确认",
          value: 2,
        },
        {
          label: "已确认",
          value: 3,
        },
        {
          label: "无需操作",
          value: 6,
        },
        {
          label: "已跳过",
          value: 7,
        },
        {
          label: "超时确认",
          value: 8,
        },
      ],
      zjOptions: [
        {
          label: "全部",
          value: 88,
        },
        {
          label: "未质检",
          value: 0,
        },
        {
          label: "待质检",
          value: 2,
        },
        {
          label: "已确认",
          value: 3,
        },
        {
          label: "无需操作",
          value: 6,
        },
        {
          label: "人工跳过",
          value: 7,
        },
        {
          label: "超时确认",
          value: 8,
        },
      ],
      skqrOptions: [
        {
          label: "全部",
          value: 88,
        },
        {
          label: "未确认",
          value: 0,
        },
        {
          label: "待确认",
          value: 2,
        },
        {
          label: "申报并扣款",
          value: 3,
        },
        {
          label: "无需操作",
          value: 6,
        },
        {
          label: "已跳过",
          value: 7,
        },
        {
          label: "超时确认",
          value: 8,
        },
        {
          label: "申报暂不扣款",
          value: 9,
        },
      ]
    };
  },
  methods: {
    changeValue() {
      this.$emit("update:modelValue", this.modelValue);
    },
  },
};
</script>
<style scoped>
.select-container {
  width: 140px;
}
</style>
