<template>
  <div style="position: relative;">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="库存现金日记账" name="first"></el-tab-pane>
      <el-tab-pane label="银行存款日记账" name="second"></el-tab-pane>
    </el-tabs>
    <div class="top_btns clearfix">
      <div class="left_box">
        <subject-list-all @success="getList" v-model:subjectId="listQuery.subjectId" :codes="$findCode(code)" clear></subject-list-all>
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px -26px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period" style="margin-left: 0;"></qzf-period>
            </el-form-item>
            <!-- <el-form-item label="会计期间 ：" :label-width="formLabelWidth">   
              <qzf-period v-model:period="listQuery.beginTime" style="margin-left: 0;"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime" style=""></qzf-period>
            </el-form-item> -->
            <el-form-item label="金额区间 ：" :label-width="formLabelWidth">   
              <el-input placeholder="金额起" v-model="listQuery.beginAmount" style="width: 136px;" size="small" /><span style="margin: 0 5px;">-</span>
              <el-input placeholder="金额止" v-model="listQuery.endAmount" style="width: 136px;" size="small" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <qzf-button jz="false"  @click="printing()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false"  @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>
    <div class="tableDl">
      <el-table stripe :height="contentStyleObj" :data="list" v-loading="listLoading" :key="tableKey"  border fit highlight-current-row @sort-change="sortChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="账期" align="center" width="110">
          <template #default="{row}">
            <span>{{ row.period }}</span>
          </template>
        </el-table-column>
        <el-table-column label="日期" align="center" width="110">
          <template #default="{row}">
            <span>{{ row.voucherDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="凭证号码" align="center" width="200">
          <template #default="{row}">
            <span>{{ row.voucherNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="摘要" align="center" width="260">
          <template #default="{row}">
            <span>{{ row.summary }}</span>
          </template>
        </el-table-column>
        <el-table-column label="借方" align="center" width="200">
          <template #default="{row}">
            <span>{{$comdify(row.inAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="贷方" align="center">
            <template #default="{row}">
              <span>{{$comdify(row.outAmount)}}</span>
            </template>
          </el-table-column>
        <el-table-column label="方向" align="center">
            <template #default="{row}">
              <span>{{row.type }}</span>
            </template>
          </el-table-column>
        <el-table-column label="余额" align="center" width="200">
          <template #default="{row}">
            <span>{{$comdify(row.endAmount)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <printVouch ref="printVouch" name="book_diary_account" :listQuery1="this.listQuery"></printVouch>
      <exportFile ref="exportFile" name="book_diary_account" :code="this.code" :code1="this.code1" :listQuery1="this.listQuery"></exportFile>
    </div>
  </div>
</template>

<script>
import { bookDetailAccountList } from "@/api/book.js"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
export default {
  name:'detailAccount',
  components: {
    printVouch,
    exportFile
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      listLoading: false,
      activeName: 'first',
      list: [],
      code: ['code1001'],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        // subjectCode: '',
        level: 0,
        from:'日记账',
        subjectId:0,
        beginAmount:0,
        endAmount:0,
        dairyType:1
        // fzhsId: 0
      },
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(260)
    this.listQuery.subjectId = this.$subjectFilterCode(this.$findCode(this.code), 2)[0].id
    this.getList()
  },
  methods: {
    printing() {
      this.$refs.printVouch.init()
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    //重置
    cancel(){
      let originSubjectId = this.listQuery.subjectId
      this.listQuery = {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        level: 0,
        from:'日记账',
        subjectId: originSubjectId,
        beginAmount:0,
        endAmount:0,
      }
      this.getList()
    },
    handleClick(tab) {
      this.code = []
      if(tab.props.name == "first"){
        this.code = ['code1001']
        let options = this.$subjectFilterCode(this.$findCode(this.code), 2)
        this.listQuery.subjectId = options[0].id
        this.listQuery.dairyType = 1
      }else if (tab.props.name == "second"){
        this.code = ['code1002']
        let options = this.$subjectFilterCode(this.$findCode(this.code), 2)
        this.listQuery.subjectId = options[0].id
        this.listQuery.dairyType = 2
      }
      this.list = []
      this.getList()
    },
    getList(){
      this.listLoading = true
      if(this.listQuery.beginAmount){
        this.listQuery.beginAmount = Number(this.listQuery.beginAmount)
      }
      if(this.listQuery.endAmount){
        this.listQuery.endAmount = Number(this.listQuery.endAmount)
      }
      bookDetailAccountList(this.listQuery).then(res=>{
        this.listLoading = false
        let list = res.data.data.info
        // list.map(item=>{
        //   item.Vouchers.unshift({
        //     inAmount:0,
        //     outAmount:0,
        //     period: item.big.period,
        //     summary: "期初余额",
        //     endAmount: item.big.type == 1?item.big.periodBeginIn:item.big.periodBeginOut,
        //   })
        //   let amount = item.big.periodBeginIn
        //   if(item.big.type != 1){
        //     amount = item.big.periodBeginOut
        //   }
        //   item.Vouchers.map(v=>{
        //     if(item.big.type == 1){
        //       amount = (amount*1 + v.inAmount*1).toFixed(2)
        //       v.endAmount = amount
        //     }else{
        //       amount = (amount*1 + v.outAmount*1).toFixed(2)
        //       v.endAmount = amount
        //     }
        //   })

          
        //   if(item.big.type == 1){
        //     amount = (amount*1 - item.big.periodOut*1).toFixed(2)
        //   }else{
        //     amount = (amount*1 - item.big.periodInt*1).toFixed(2)
        //   }
        //   item.Vouchers.push({
        //     inAmount:0,
        //     outAmount:0,
        //     period: item.big.period,
        //     // summary: this.getText(item.big.subjectCode),
        //     amount: item.big.type == 1?item.big.periodOut:item.big.periodInt,
        //     endAmount: amount,
        //   })
        // })
        this.list = list
      })
    },
    getText(code){
      if(code == '4101'){//制造费用
        return "转入生产成本"
      }else if(code == '4001'){//生产成本
        return "转入库存商品"
      }else if(code == '4002'){//劳务成本
        return "转入主营业务成本"
      }else if(code == '5403' || code == '5602' || code == '5603' || code == '5601' || code == '5401' || code == '5402' || code == '5001' || code == '5051'){//管理费用 等损益科目
        return "转入本年利润"
      }else if(code == '4301'){//研发支出
        return "转入无形资产"
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.app-container{
  h5{
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.top_btns {
   .left_box{
    float: left;
    width: 76%;
  }
  .right_box {
    float: right;
    width: 20%;
    text-align: right;
  }
  .time_box{
    float: left;
  }
  .bottom_btns{
    float: left;
    // margin-left: 20px;
  }
}
.tableDl {
  margin-top: 15px;
}
</style>