<template>
  <div style="position: relative;">
    <div class="top_btns clearfix">
      <div class="left_box">
        <qzf-period v-model:period="listQuery.beginTime" :placeholder="'会计账期起'" :clearable="true" style="width: 110px;"></qzf-period>
          <span>-</span>
        <qzf-period v-model:period="listQuery.endTime" :placeholder="'会计账期止'" :clearable="true" style="width: 110px;"></qzf-period>
        <el-button size="small" icon="Search" type="primary" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 0px;width:430px" class="styleForm">
           
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period" style="margin:0"></qzf-period>
            </el-form-item>
            <el-form-item label="选择级别 ：" :label-width="formLabelWidth">
              <el-input placeholder="1" style="width: 66px;" class="filter-item" size="small" :disabled="true" />
              <span>-</span>
              <el-input placeholder="请输入级别" v-model.number="listQuery.level" style="width: 66px;margin-right:10px" class="filter-item" size="small" />
            </el-form-item>
            <el-form-item label="科目编码 ：" :label-width="formLabelWidth">
              <subject-list-all v-model:subjectId="listQuery.subjectId" style="margin:0"></subject-list-all>
            </el-form-item>
            <!-- <el-form-item label="会计期间 ：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime" style="margin:0"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime" style="margin:0"></qzf-period>
            </el-form-item> -->
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.jeNot0" label="金额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fseNot0" label="发生额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fse0Ye1" label="无发生额且余额为0不显示"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <qzf-button jz="false" @click="printing()" type="success" size="small">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </qzf-button>
        <qzf-button jz="false"  @click="daochu()" type="primary" size="small">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </qzf-button>
      </div>
    </div>
    <el-table stripe :height="contentStyleObj"  v-loading="listLoading" :key="tableKey" :data="seachlist" border fit highlight-current-row @sort-change="sortChange" style="width: 100%">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" align="center">
        <template #default="scope">
          <span class="bianma" @click="gotoDetail(scope.row)">{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="科目名称" align="center">
        <template #default="scope">
          <span>{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="期间" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{item.period}}</p>
            <p>{{item.period}}</p>
            <p>{{item.period}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="摘要" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>期初余额</p>
            <p>本期合计</p>
            <p>本年累计</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="借方" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{ $comdify(item.periodBeginIn)}}</p>
            <p>{{ $comdify(item.periodInt) }}</p>
            <p>{{ $comdify(item.yearInt)}}</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="贷方" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{ $comdify(item.periodBeginOut)}}</p>
            <p>{{ $comdify(item.periodOut)}}</p>
            <p>{{ $comdify(item.yearOut)}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="余额" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{ item.endAmount1}}</p>
            <p>{{ item.endAmount2}}</p>
            <p>{{ item.endAmount3}}</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <printVouch ref="printVouch" name="book_all_account" :listQuery1="this.listQuery"></printVouch>
    <exportFile ref="exportFile" name="book_all_account" :listQuery1="this.listQuery"></exportFile>
  </div>
</template>

<script>
import { bookAllAccountList } from "@/api/book.js"
// import { accountBook,exportBook } from "@/api/printSet"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
import SubjectListAll from '../../../components/subject/subjectListAll.vue'
export default {
  name:'generalLedger',
  components: {
    printVouch,
    exportFile,
    SubjectListAll
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      seachlist: [],
      list: null,
      listLoading: false,
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        not0: false,
        fseNot0: false,
        fse0Ye1: false,
        name: '',
        level: 1,
        beginTime:'',
        endTime:'',
        jeNot0:false
      },
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(210)
    this.getList()
  },
  watch: {

  },
  methods: {
    printing() {
      this.$refs.printVouch.init()
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    getList() {
      // this.listLoading = true;
      bookAllAccountList(this.listQuery).then(res => {
        this.listLoading = false;
        if(!res.data.data.info){
          res.data.data.info = []
        }
        let list = res.data.data.info;
        list.map(item=>{
          item.item.map(v=>{
            v.endAmount1 = v.beginType + " " + this.$comdify(v.endAmount1)
            v.endAmount2 = v.periodType + " " + this.$comdify(v.endAmount2)
            v.endAmount3 = v.endType + " " + this.$comdify(v.endAmount3)
          })
        })
        this.seachlist = list;
      });
    },
    //重置
    cancel(){
      this.listQuery= {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        not0: false,
        fseNot0: false,
        fse0Ye1: false,
        name: '',
        level: 1,
        beginTime:'',
        endTime:'',
        jeNot0:false
      }
      this.getList()
    },
    gotoDetail(row){
      this.$emit('success',row)
    }
  }
}
</script>

<style lang="scss" scoped>
.searchGjz {
  border: 1px solid #ebeef5;
}
.searchBtn {
  text-align: center;
  button {
    margin-right: 5px;
    padding: 10px 0 10px 0;
  }
}
.top_btns {
  margin-bottom: 10px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.bianma{
  cursor: pointer;
  color: #17a2b8;
}
</style>